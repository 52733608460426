import { Recipe } from "schema-dts"
import React from "react"
import { JsonLd } from "react-schemaorg"

export function Resep({
  title,
  gambar,
  date,
  modi,
  diskripsi,
  jenis,
  waktuPersiapan,
  waktumasak,
  total,
  tags,
  jadi,
  tipe,
  nutrisi,
  rating,
  ratingTotal,
  bahan,
  cara,
}) {
  return (
    <JsonLd<Recipe>
      item={{
        "@context": "https://schema.org",
        "@type": "Recipe",
        name: title,
        image: [gambar],
        author: {
          "@type": "Organization",
          name: "Hajebo.com",
        },
        datePublished: date,
        dateModified: modi,
        description: diskripsi,
        recipeCuisine: jenis,
        prepTime: waktuPersiapan,
        cookTime: waktumasak,
        totalTime: total,
        keywords: tags,
        recipeYield: jadi,
        recipeCategory: tipe,
        nutrition: {
          "@type": "NutritionInformation",
          calories: nutrisi,
        },
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: rating,
          ratingCount: ratingTotal,
        },
        recipeInstructions: [cara],
        recipeIngredient: [bahan],
      }}
    />
  )
}

export default Resep
